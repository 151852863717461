@font-face {
	font-family: "Noto Sans JP", sans-serif;
	src: url("../src/assets/fonts/NotoSansJP-Black.otf"),
		url("../src/assets/fonts/NotoSansJP-Bold.otf"), url("../src/assets/fonts/NotoSansJP-Light.otf"),
		url("../src/assets/fonts/NotoSansJP-Medium.otf"),
		url("../src/assets/fonts/NotoSansJP-Regular.otf"),
		url("../src/assets/fonts/NotoSansJP-Thin.otf");
}

body {
	background-color: #edf2fb;

	.bg-site-main-orange {
		background-color: #ffd6a5;
	}

	.bg-site-main-violet {
		background-color: #a289d2;
	}

	.pointer {
		cursor: pointer;
	}
}
